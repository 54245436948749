exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-directors-js": () => import("./../../../src/pages/about/directors.js" /* webpackChunkName: "component---src-pages-about-directors-js" */),
  "component---src-pages-about-insights-js": () => import("./../../../src/pages/about/insights.js" /* webpackChunkName: "component---src-pages-about-insights-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-our-partners-js": () => import("./../../../src/pages/about/our-partners.js" /* webpackChunkName: "component---src-pages-about-our-partners-js" */),
  "component---src-pages-about-privacy-policy-js": () => import("./../../../src/pages/about/privacy-policy.js" /* webpackChunkName: "component---src-pages-about-privacy-policy-js" */),
  "component---src-pages-about-testimonials-js": () => import("./../../../src/pages/about/testimonials.js" /* webpackChunkName: "component---src-pages-about-testimonials-js" */),
  "component---src-pages-case-studies-argonaut-mdx": () => import("./../../../src/pages/case-studies/argonaut.mdx" /* webpackChunkName: "component---src-pages-case-studies-argonaut-mdx" */),
  "component---src-pages-case-studies-dial-a-doctor-mdx": () => import("./../../../src/pages/case-studies/dial-a-doctor.mdx" /* webpackChunkName: "component---src-pages-case-studies-dial-a-doctor-mdx" */),
  "component---src-pages-case-studies-giorgi-mdx": () => import("./../../../src/pages/case-studies/giorgi.mdx" /* webpackChunkName: "component---src-pages-case-studies-giorgi-mdx" */),
  "component---src-pages-case-studies-goldfields-money-mdx": () => import("./../../../src/pages/case-studies/goldfields-money.mdx" /* webpackChunkName: "component---src-pages-case-studies-goldfields-money-mdx" */),
  "component---src-pages-case-studies-gumala-aboriginal-group-mdx": () => import("./../../../src/pages/case-studies/gumala-aboriginal-group.mdx" /* webpackChunkName: "component---src-pages-case-studies-gumala-aboriginal-group-mdx" */),
  "component---src-pages-case-studies-hanking-gold-mining-mdx": () => import("./../../../src/pages/case-studies/hanking-gold-mining.mdx" /* webpackChunkName: "component---src-pages-case-studies-hanking-gold-mining-mdx" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-kddi-mdx": () => import("./../../../src/pages/case-studies/kddi.mdx" /* webpackChunkName: "component---src-pages-case-studies-kddi-mdx" */),
  "component---src-pages-case-studies-surf-life-saving-wa-mdx": () => import("./../../../src/pages/case-studies/surf-life-saving-wa.mdx" /* webpackChunkName: "component---src-pages-case-studies-surf-life-saving-wa-mdx" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-support-business-helpdesk-support-perth-js": () => import("./../../../src/pages/it-support/business-helpdesk-support-perth.js" /* webpackChunkName: "component---src-pages-it-support-business-helpdesk-support-perth-js" */),
  "component---src-pages-it-support-cloud-services-perth-js": () => import("./../../../src/pages/it-support/cloud-services-perth.js" /* webpackChunkName: "component---src-pages-it-support-cloud-services-perth-js" */),
  "component---src-pages-it-support-cyber-resilience-assessments-js": () => import("./../../../src/pages/it-support/cyber-resilience-assessments.js" /* webpackChunkName: "component---src-pages-it-support-cyber-resilience-assessments-js" */),
  "component---src-pages-it-support-emergency-detection-and-response-js": () => import("./../../../src/pages/it-support/emergency-detection-and-response.js" /* webpackChunkName: "component---src-pages-it-support-emergency-detection-and-response-js" */),
  "component---src-pages-it-support-hardware-software-procurement-js": () => import("./../../../src/pages/it-support/hardware-software-procurement.js" /* webpackChunkName: "component---src-pages-it-support-hardware-software-procurement-js" */),
  "component---src-pages-it-support-it-project-management-perth-js": () => import("./../../../src/pages/it-support/it-project-management-perth.js" /* webpackChunkName: "component---src-pages-it-support-it-project-management-perth-js" */),
  "component---src-pages-it-support-it-support-services-perth-js": () => import("./../../../src/pages/it-support/it-support-services-perth.js" /* webpackChunkName: "component---src-pages-it-support-it-support-services-perth-js" */),
  "component---src-pages-it-support-security-training-js": () => import("./../../../src/pages/it-support/security-training.js" /* webpackChunkName: "component---src-pages-it-support-security-training-js" */),
  "component---src-pages-it-support-technology-consulting-perth-js": () => import("./../../../src/pages/it-support/technology-consulting-perth.js" /* webpackChunkName: "component---src-pages-it-support-technology-consulting-perth-js" */),
  "component---src-pages-partnership-js": () => import("./../../../src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

